import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from "@angular/cdk/menu";
import { ChangeDetectionStrategy, Component, computed, inject } from "@angular/core";
import { RouterLink } from "@angular/router";
import { SvgIconComponent } from "angular-svg-icon";
import { OrganisationsService } from "src/app/core/services/organisations.service";
import { ProjectsService } from "src/app/core/services/projects.service";
import { UserService } from "src/app/core/services/user.service";

@Component({
  selector: 'app-project-header',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `@if(currentProject(); as project){
    <button [cdkMenuTriggerFor]="isGuest () ? null : projectsList" class="flex items-center" (click)="openProjects()">
      <h2 class="font-semibold text-center leading-none">{{project.name}}</h2>
      @if(!isGuest()){
        <svg-icon src="/assets/icons/arrow.svg" class="w-3 h-3 ml-2 text-[#878F97]" />
      }
    </button>

    <ng-template #projectsList>
      <ul class="dropdown" cdkMenu>
        @for(project of projects(); track project.id){
        <li cdkMenuItem [routerLink]="['/', 'projects', project.id]">
          <a  class="w-full h-full px-2 py-1 block">{{project.name}}</a>
        </li>
        }
      </ul>
    </ng-template>
  }
  `,
  imports: [
    SvgIconComponent,
    RouterLink,
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuItem
  ],
  host: {
    class: 'flex w-full justify-center items-center max-md:justify-start'
  }
})
export class ProjectHeaderComponent {
  private projectsService = inject(ProjectsService);
  private organisationsService = inject(OrganisationsService);
  private userService = inject(UserService);

  currentProject = this.projectsService.currentProject;
  isGuest = this.userService.isGuest;

  projects = computed(() => {
    const projects = this.projectsService.projects();
    if (typeof projects === 'undefined') {
      return [];
    }
    const organisationId = this.organisationsService.currentOrganisationId();
    return projects.filter(p => p.organisationId === organisationId);
  });

  openProjects() {
    if (this.userService.isGuest()) {
      return;
    }

    const organisationId = this.organisationsService.currentOrganisationId();
    if (typeof this.projectsService.projects() === 'undefined') {
      this.projectsService.query(organisationId!).subscribe(r => this.projectsService.projects.set(r));
    }
  }
}
