import { computed, inject, Inject, Injectable, InjectionToken, Optional, signal } from "@angular/core";
import { feature as featureHelper, MultiPolygon, Polygon } from '@turf/helpers';
import { difference, polygon } from '@turf/turf';
import { Feature, FeatureCollection, Geometry } from "geojson";
import mapboxgl, { FeatureIdentifier } from "mapbox-gl";
import { debounceTime, filter, fromEvent, Observable, Subject, takeUntil } from "rxjs";
import { environment } from "src/environments/environment";
import { ProjectMapLayer } from "../models/project.model";
import { DataLayersService } from "./datalayers.service";
import { toObservable } from "@angular/core/rxjs-interop";
import { lineColors, lineDashColors, symbologyColors, SymbologyService } from "./symbology.service";

export interface UKHabDataLayer {
  id: string;
  label: string;
  color: string;
  source: string;
  active: boolean;
}

export interface MapGISSettings {
  snapping: boolean;
  preventOverlaps: boolean;
  showMetrics: boolean;
  snapToBaseLayer: boolean;
  simplificationAmount: number;
  snapTolerance: number;
}

const defaultMapGISSettings: MapGISSettings = {
  snapping: false,
  preventOverlaps: false,
  showMetrics: false,
  snapToBaseLayer: false,
  simplificationAmount: 3,
  snapTolerance: 25,
};

export type DistinctivenessMapKey = 0 | 2 | 4 | 6 | 8 | 10;

export const MAP_DEFAULT_BOUNDS: mapboxgl.LngLatBoundsLike = [
  2.0153808593734936, 56.6877748258257,
  -7.0153808593762506, 47.45206245445874
];

export const mapboxStreetsStyle = 'mapbox://styles/mapbox/streets-v11';
export const mapboxSatelliteStyle = 'mapbox://styles/mapbox/satellite-v9';
export const emptyStyle: mapboxgl.Style = {
  version: 8,
  name: 'empty',
  metadata: {
    'mapbox:autocomposite': true,
    'mapbox:type': 'template'
  },
  glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  sources: {},
  layers: [
    {
      id: 'background',
      type: 'background',
      paint: {
        'background-color': 'rgba(255,255,255,0)'
      }
    }
  ]
};

const fillOpacity = (opacity: number): mapboxgl.Expression => {
  const factor = opacity / 100;
  return [
    'case',
    ['boolean', ['feature-state', 'editing'], false],
    0.0 * factor,
    ['boolean', ['feature-state', 'hover'], false],
    0.9 * factor,
    ['boolean', ['feature-state', 'selected'], false],
    1.0 * factor,
    0.7 * factor
  ]
}

const styles = {
  'streets': mapboxStreetsStyle,
  'satellite': mapboxSatelliteStyle,
  'bing': null,
  'empty': emptyStyle
} as const;

export type MapStyleId = keyof typeof styles;
export type MapSymbologyId = 'standard' | 'distinctiveness';

export const emptyFeatureCollection = <T = any>(): FeatureCollection<Geometry, T> => ({
  type: 'FeatureCollection',
  features: []
});

export const pointToBox = (point: mapboxgl.Point, boxSize: number): [mapboxgl.PointLike, mapboxgl.PointLike] => {
  return [
    [point.x - boxSize, point.y - boxSize],
    [point.x + boxSize, point.y + boxSize]
  ];
};

const safelyAddSource = (map: mapboxgl.Map, sourceId: string, source: mapboxgl.AnySourceData) => {
  if (!map.getSource(sourceId)) {
    map.addSource(sourceId, source);
  }
}

const safelyAddLayer = (map: mapboxgl.Map, layer: mapboxgl.AnyLayer) => {
  if (!map.getLayer(layer.id)) {
    map.addLayer(layer);
  }
}

const safelyRemoveLayer = (map: mapboxgl.Map, layerId: string) => {
  if (map.getLayer(layerId)) {
    map.removeLayer(layerId);
  }
}

const safelyRemoveSource = (map: mapboxgl.Map, sourceId: string) => {
  if (map.getSource(sourceId)) {
    map.removeSource(sourceId);
  }
}

export const MAPS_SERVICE_PERSIST = new InjectionToken<boolean>('MAPS_SERVICE_PERSIST');

interface MapHoveredFeature {
  id: number | string;
  layerId: string;
  sourceId: string;
  sourceLayer: string;
  properties: any;
  type: 'feature' | 'data' | 'custom'
}

type CreateMapOptions = mapboxgl.MapboxOptions & {

}

@Injectable()
export class MapsService {

  dataLayerService = inject(DataLayersService);
  symbologyService = inject(SymbologyService);

  public readonly style = signal<MapStyleId>('streets');
  public readonly symbology = signal<MapSymbologyId>('standard');
  public readonly styleLoaded = signal<boolean>(false);
  public readonly featureOpacity = signal<number>(100);
  public readonly settingsOpen = signal<boolean>(false);
  public readonly hoveredFeature = signal<MapHoveredFeature | null>(null);
  public readonly drawMode = signal<boolean>(false);
  public readonly styleLoad = toObservable(this.styleLoaded).pipe(filter(Boolean));

  public readonly DATA_SOURCE_NAME = 'data';
  public readonly REDLINE_SOURCE_NAME = 'redline';
  public readonly DATA_LAYERS_SOURCES = ['ukhab-portal', 'ukhab-portal-v2'];
  public readonly NOTES_DATA_SOURCE = 'notes';
  public readonly OVERLAPS_SOURCE = 'overlaps';
  public readonly GAPS_SOURCE = 'gaps';

  public readonly POINTS_LAYER_ID = 'points';
  public readonly TREES_LAYER_ID = 'trees';
  public readonly NOTES_LAYER_ID = 'notes';
  public readonly POLYGONS_LAYER_ID = 'polygons';
  public readonly POLYGONS_OUTLINE_LAYER_ID = 'polygons-outline';
  public readonly LINES_LAYER_ID = 'lines';
  public readonly LINES_DASHES_LAYER_ID = 'lines-dashes';

  private map: mapboxgl.Map | undefined;
  private popup: mapboxgl.Popup | undefined;
  private popupContentElement: WeakRef<HTMLElement> | undefined;
  release$ = new Subject<void>();

  private customLayerIds: string[] = [];

  public featureLayerIds = [
    this.POINTS_LAYER_ID,
    this.LINES_LAYER_ID,
    this.POLYGONS_LAYER_ID,
    this.TREES_LAYER_ID,
    this.NOTES_LAYER_ID
  ];

  private persist: boolean = true;
  private mapLayersRenderer: MapLayersRenderer;

  private static readonly STORAGE_STYLE_KEY = 'map-style';
  private static readonly STORAGE_OPACITY_KEY = 'map-opacity';
  private static readonly STORAGE_SYMBOLOGY_KEY = 'map-symbology';


  constructor(
    @Optional() @Inject(MAPS_SERVICE_PERSIST) persist: boolean
  ) {
    const style = this.getStoredStyle();
    const opacity = this.getStoredOpacity();
    const symbology = this.getStoredSymbology();

    this.persist = persist ?? true;

    if (style) {
      this.style.set(style as MapStyleId);
    }

    if (opacity) {
      this.featureOpacity.set(opacity);
    }

    if (symbology) {
      this.symbology.set(symbology as MapSymbologyId);
    }
  }

  private getStoredStyle(): MapStyleId {
    return (localStorage.getItem(MapsService.STORAGE_STYLE_KEY) ?? 'streets') as MapStyleId;
  }

  private getStoredOpacity(): number {
    return parseInt(localStorage.getItem(MapsService.STORAGE_OPACITY_KEY) || '') ?? 100;
  }

  private getStoredSymbology(): MapSymbologyId | null {
    return localStorage.getItem(MapsService.STORAGE_SYMBOLOGY_KEY) as MapSymbologyId;
  }

  private async loadBingMapStyle(): Promise<mapboxgl.Style> {
    const imagerySet = 'Aerial';
    const culture = 'en-GB';
    const d = await fetch(`https://dev.virtualearth.net/REST/V1/Imagery/Metadata/${imagerySet}?output=json&uriScheme=https&include=ImageryProviders&key=${environment.bingMapsApiKey}`);
    const data = await d.json();
    const resourceSets = data.resourceSets[0];
    const resources = resourceSets.resources;
    const resource = resources[0];

    const imageUrl: string = resource.imageUrl;
    const imageUrlSubdomains: string[] = resource.imageUrlSubdomains;

    const tiles = imageUrlSubdomains.map(subdomain => {
      return imageUrl.replace('{subdomain}', subdomain)
        .replace('{culture}', culture);
    });

    const minzoom = resource.zoomMin;
    // 20 seems to be the practical max zoom level, so don't go below that
    const maxzoom = Math.min(resource.zoomMax, 20);

    return {
      version: 8,
      glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
      name: 'Bing Maps',
      sources: {
        'bing': {
          type: 'raster',
          tiles: tiles,
          tileSize: 256,
          minzoom,
          maxzoom
        }
      },
      layers: [{
        id: 'bing',
        type: 'raster',
        source: 'bing'
      }]
    };
  }

  async getStyle(styleId: MapStyleId): Promise<mapboxgl.Style | string> {
    if (styleId === 'bing') {
      return this.loadBingMapStyle();
    }
    return styles[styleId];
  }

  async setStyle(styleId: MapStyleId, persist: boolean = this.persist) {
    this.styleLoaded.set(false);

    return new Promise<void>(async (resolve) => {
      const style = await this.getStyle(styleId);
      this.style.set(styleId);
      this.map!.once('style.load', () => {
        if (persist) {
          localStorage.setItem(MapsService.STORAGE_STYLE_KEY, styleId as string);
        }
        resolve();
      });
      this.map!.setStyle(style, { diff: false });
    });
  }

  async setSymbology(symbologyId: any) {
    this.styleLoaded.set(false);
    const style = await this.getStyle(this.style());
    this.map!.setStyle('').setStyle(style);
    this.symbology.set(symbologyId);
    if (this.persist) {
      localStorage.setItem(MapsService.STORAGE_SYMBOLOGY_KEY, symbologyId as string);
    }
  }

  async setDrawMode(drawMode: boolean) {
    if (drawMode) {
      this.setFeatureOpacity(0, false);
      this.setStyle('empty', false);
    } else {
      await this.setStyle(this.getStoredStyle(), false);
      this.setFeatureOpacity(this.getStoredOpacity(), false);
    }
    this.drawMode.set(drawMode);
  }

  async createMap(options: CreateMapOptions) {
    const style = await this.getStyle(this.style());
    const map = new mapboxgl.Map({
      ...options,
      accessToken: environment.mapboxApiKey,
      style,
      trackResize: false
    });

    // Setup events we want to handle
    map.on('styleimagemissing', this.handleStyleImageMissing);
    map.on('style.load', this.handleStyleLoad);
    map.on('mousemove', this.handleMouseMove);
    map.on('mouseout', this.handleMouseLeave);

    this.mapLayersRenderer = new MapLayersRenderer(map);
    this.map = map;
    this.resizeObservable(map.getContainer()).pipe(
      debounceTime(200),
      takeUntil(this.release$)
    ).subscribe(() => {
      this.map?.resize();
    });

    return map;
  }

  releaseMap() {
    const map = this.map;
    this.styleLoaded.set(false);
    if (!map) {
      return;
    }
    map.off('style.load', this.handleStyleLoad);
    map.off('mousemove', this.handleMouseMove);
    map.remove();
    this.release$.next();
    this.release$.complete();
    this.map = undefined;
  }

  handleStyleImageMissing = (e: any) => {
    const map = this.map!;
    if (e.id === 'tree') {
      return map.loadImage('./assets/ukhab-sybmology/tree.png', (error, image: any) => {
        if (error) {
          throw error;
        }
        if (!map.hasImage('tree')) {
          map.addImage('tree', image);
        }
      });
    }
    const [code, distinctiv, zoom] = e.id.split('-');
    map.addImage(e.id, this.symbologyService.generateSymbology(this.symbology(), code, parseInt(distinctiv || '10'), parseInt(zoom)));
  }

  handleStyleLoad = () => {
    this.dataLayerService.addToMap(this.map!);
    this.addMapSources();
    this.addMapLayers();
    this.styleLoaded.set(true);
  }

  handleMouseMove = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    if (!this.styleLoaded()) {
      return;
    }

    const box = pointToBox(e.point, 10);
    const map = e.target;
    const [feature] = map.queryRenderedFeatures(box, {
      validate: false,
      layers: [
        ...this.featureLayerIds,
        ...this.dataLayerService.activeIds(),
        ...this.customLayerIds
      ]
    });

    if (!feature) {
      return this.clearHoveredFeature();
    }

    let type: MapHoveredFeature['type'] = 'custom';
    if (this.DATA_LAYERS_SOURCES.includes(feature.source)) {
      type = 'data';
    } else if (this.featureLayerIds.includes(feature.layer.id)) {
      type = 'feature';
    }

    this.setHoveredFeature({
      id: feature.id!,
      layerId: feature.layer.id,
      sourceId: feature.source,
      sourceLayer: feature.sourceLayer,
      properties: feature.properties,
      type
    });
  }

  handleMouseLeave = () => {
    this.clearHoveredFeature();
  }

  handleMapZoom = () => {
    this.clearHoveredFeature();
  }

  public setPopupContentElement(element: HTMLElement) {
    this.popupContentElement = new WeakRef(element);
    if (this.popup) {
      this.popup.setDOMContent(element);
    }
  }

  public clearPopupContentElement() {
    this.popupContentElement = undefined;
    this.clearPopup();
  }

  private setHoveredFeature(feature: MapHoveredFeature): void {

    const current = this.hoveredFeature();
    // If it's the same feature, do nothing
    if (current && current.id === feature.id && current.layerId === feature.layerId) {
      return;
    }

    // Else if we have a current, remove the hover state
    if (current) {
      this.map!.removeFeatureState({
        source: current.sourceId,
        sourceLayer: current.sourceLayer,
        id: current.id
      }, 'hover');
    }

    const featureIdentifier: FeatureIdentifier = {
      source: feature.sourceId,
      sourceLayer: feature.sourceLayer,
      id: feature.id
    };

    const state = this.map!.getFeatureState(featureIdentifier);
    if (state['editing']) {
      return;
    }

    this.hoveredFeature.set(feature);

    // Sometimes a race condition can result in the state having been set back to editing,
    // even through we checked above. So set it to editing false here
    this.map!.setFeatureState(featureIdentifier, {
      hover: true,
      editing: false
    });

    if (this.popup) {
      return;
    }

    if (this.popupContentElement) {
      this.popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        className: 'project-map-popup pointer-events-none',
        maxWidth: '600px',
        offset: 10,
      });
      this.popup.setDOMContent(this.popupContentElement.deref()!);
      this.popup.trackPointer();
      this.popup.addTo(this.map!);
      this.map!.getCanvas().style.cursor = 'pointer';
    }
  }

  clearHoveredFeature() {
    const current = this.hoveredFeature();
    if (!current) {
      return;
    }

    if (current) {
      this.map!.removeFeatureState({
        source: current.sourceId,
        sourceLayer: current.sourceLayer,
        id: current.id
      }, 'hover');
    }

    this.hoveredFeature.set(null);
    this.map!.getCanvas().style.cursor = '';
    this.clearPopup();
  }

  clearPopup() {
    this.popup?.remove();
    this.popup = undefined;
  }

  treeFilter(filter: mapboxgl.Expression, withTrees: boolean = true) {
    const b = this.geometryTypeFilter('Point', filter, ['baseline', 'pre-survey']);
    b.push([withTrees ? '==' : '!=', ['get', 'metBrdHab'], 'Individual trees']);
    return b;
  }

  geometryTypeFilter(type: 'Polygon' | 'LineString' | 'Point', filter: mapboxgl.Expression, types: string[] = []) {
    const f = ['all', ['==', ['geometry-type'], type], filter];
    if (types.length > 0) {
      const tf: mapboxgl.Expression = ['any']
      for (const t of types) {
        tf.push(['==', ['get', '__type'], ['literal', t]]);
      }
      f.push(tf);
    }
    return f;
  }

  applyFilter(filter: mapboxgl.Expression) {
    const map = this.map!;
    const pointFilter = this.treeFilter(filter, false);
    const treeFilter = this.treeFilter(filter, true);

    map.setFilter(this.POLYGONS_LAYER_ID, this.geometryTypeFilter('Polygon', filter));
    map.setFilter(this.POLYGONS_OUTLINE_LAYER_ID, this.geometryTypeFilter('Polygon', filter));
    map.setFilter(this.LINES_LAYER_ID, this.geometryTypeFilter('LineString', filter));
    map.setFilter(this.LINES_DASHES_LAYER_ID, this.geometryTypeFilter('LineString', filter));
    map.setFilter(this.POINTS_LAYER_ID, pointFilter);
    map.setFilter(this.TREES_LAYER_ID, treeFilter);
    map.setFilter(this.NOTES_LAYER_ID, this.geometryTypeFilter('Point', filter, ['species-feature-notes']));
  }

  applyState(selectedIds: (number | string)[], editingId?: number | string) {
    const map = this.map!;
    map.removeFeatureState({
      source: this.DATA_SOURCE_NAME
    });

    for (const id of selectedIds) {
      map.setFeatureState({
        source: this.DATA_SOURCE_NAME,
        id
      }, {
        selected: true
      });
    }

    if (editingId) {
      map.setFeatureState({
        source: this.DATA_SOURCE_NAME,
        id: editingId
      }, {
        editing: true
      });
    }
  }

  getCurrentBounds() {
    return this.map!.getBounds();
  }

  updateFeatures(map: mapboxgl.Map, features: FeatureCollection) {
    const source = map.getSource(this.DATA_SOURCE_NAME) as mapboxgl.GeoJSONSource;
    if (typeof source !== 'undefined') {
      source.setData(features);
    }
  }

  addMapSources() {
    const map = this.map!;
    safelyAddSource(map, this.DATA_SOURCE_NAME, {
      type: 'geojson',
      data: emptyFeatureCollection()
    });
    safelyAddSource(map, this.OVERLAPS_SOURCE, {
      type: 'geojson',
      data: emptyFeatureCollection()
    });
    safelyAddSource(map, this.GAPS_SOURCE, {
      type: 'geojson',
      data: emptyFeatureCollection()
    });
    safelyAddSource(map, this.REDLINE_SOURCE_NAME, {
      type: 'geojson',
      data: emptyFeatureCollection()
    });
  }

  addMapLayers() {
    const map = this.map!;


    map.addLayer({
      id: this.POLYGONS_LAYER_ID,
      type: 'fill',
      source: this.DATA_SOURCE_NAME,
      paint: {
        'fill-pattern': [
          "step",
          ["zoom"],
          ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-0"],
          1, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-1"],
          2, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-2"],
          3, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-3"],
          4, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-4"],
          5, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-5"],
          6, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-6"],
          7, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-7"],
          8, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-8"],
          9, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-9"],
          10, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-10"],
          11, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-11"],
          12, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-12"],
          13, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-13"],
          14, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-14"],
          15, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-15"],
          16, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-16"],
          17, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-17"],
          18, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-18"],
          19, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-19"],
          20, ["concat", ["get", "ukHabPCode"], "-", ["get", "distinctiv"], "-20"]
        ],
        'fill-opacity': fillOpacity(this.featureOpacity())
      }
    });

    map.addLayer({
      id: this.POLYGONS_OUTLINE_LAYER_ID,
      type: 'line',
      source: this.DATA_SOURCE_NAME,
      paint: {
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'editing'], false],
          0,
          ['boolean', ['feature-state', 'hover'], false],
          4,
          ['boolean', ['feature-state', 'selected'], false],
          3,
          1
        ]
      }
    });

    map.addLayer({
      id: this.LINES_LAYER_ID,
      type: 'line',
      source: this.DATA_SOURCE_NAME,
      paint: {
        'line-color': lineColors,
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'editing'], false],
          0,
          ['boolean', ['feature-state', 'hover'], false],
          5,
          ['boolean', ['feature-state', 'selected'], false],
          4,
          3
        ]
      }
    });

    map.addLayer({
      id: this.LINES_DASHES_LAYER_ID,
      type: 'line',
      source: this.DATA_SOURCE_NAME,
      paint: {
        'line-color': lineDashColors,
        'line-dasharray': [2, 4],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'editing'], false],
          0,
          ['boolean', ['feature-state', 'hover'], false],
          5,
          ['boolean', ['feature-state', 'selected'], false],
          4,
          3
        ]
      }
    });

    map.addLayer({
      id: this.POINTS_LAYER_ID,
      type: 'circle',
      source: this.DATA_SOURCE_NAME,
      paint: {
        'circle-color': symbologyColors,
        'circle-radius': [
          'case',
          ['boolean', ['feature-state', 'editing'], false],
          0,
          ['boolean', ['feature-state', 'hover'], false],
          9,
          ['boolean', ['feature-state', 'selected'], false],
          7,
          5
        ]
      }
    });

    map.addLayer({
      id: this.TREES_LAYER_ID,
      type: 'symbol',
      source: this.DATA_SOURCE_NAME,
      layout: {
        'icon-image': 'tree',
        "icon-size": ['interpolate', ['linear'], ['zoom'], 12, 0.001, 20, 0.1],
        'icon-ignore-placement': true,
        'icon-allow-overlap': true
      }
    });

    map.addLayer({
      id: this.NOTES_LAYER_ID,
      type: 'circle',
      source: this.DATA_SOURCE_NAME,
      paint: {
        'circle-color': '#002Aff',
        'circle-radius': [
          'case',
          ['boolean', ['feature-state', 'editing'], false],
          0,
          ['boolean', ['feature-state', 'hover'], false],
          9,
          ['boolean', ['feature-state', 'selected'], false],
          7,
          5
        ]
      }
    });

    map.addLayer({
      id: 'overlaps',
      type: 'fill',
      paint: {
        'fill-color': '#ff0000',
        'fill-opacity': 0.6
      },
      source: 'overlaps'
    });

    map.addLayer({
      id: 'gaps',
      type: 'fill',
      paint: {
        'fill-color': '#ff0000',
        'fill-opacity': 0.6
      },
      source: 'gaps'
    });

    map.addLayer({
      id: 'overlaps-outline',
      type: 'line',
      paint: {
        'line-color': '#ff0000',
        'line-width': 2
      },
      source: 'overlaps'
    });

    map.addLayer({
      id: 'gaps-outline',
      type: 'line',
      paint: {
        'line-color': '#ff0000',
        'line-width': 2
      },
      source: 'gaps'
    });

    map.addLayer({
      id: this.REDLINE_SOURCE_NAME,
      source: this.REDLINE_SOURCE_NAME,
      type: 'line',
      paint: {
        'line-color': '#ff0000',
        'line-width': 3
      }
    });
  }

  loadMapLayers(features: FeatureCollection, filter: mapboxgl.Expression, boundary: Feature<Geometry> | null) {

    const map = this.map!;

    // Update Data
    const source = map.getSource(this.DATA_SOURCE_NAME) as mapboxgl.GeoJSONSource;
    source.setData(features);

    // Update Redline
    const redlineSource = map.getSource(this.REDLINE_SOURCE_NAME) as mapboxgl.GeoJSONSource;
    redlineSource.setData(boundary ?? emptyFeatureCollection());

    this.applyFilter(filter);
  }

  addCustomLayer(layer: ProjectMapLayer) {
    this.customLayerIds.push(...this.mapLayersRenderer.addLayer(layer));
  }

  clearCustomLayers() {
    this.customLayerIds.forEach(id => {
      this.map!.removeLayer(id);
    });
    this.customLayerIds = [];
  }

  loadCustomLayers(layers: ProjectMapLayer[]) {
    if (!this.mapLayersRenderer) {
      return;
    }

    this.customLayerIds = this.mapLayersRenderer.renderLayers(layers);
  }

  showCustomLayer(layer: ProjectMapLayer) {
    this.mapLayersRenderer.showLayer(layer);
  }

  hideCustomLayer(layer: ProjectMapLayer) {
    this.mapLayersRenderer.hideLayer(layer);
  }

  toggleCustomLayer(layer: ProjectMapLayer) {
    return this.mapLayersRenderer.toggleLayer(layer);
  }

  removeCustomLayer(layer: ProjectMapLayer) {
    const ids = this.mapLayersRenderer.removeLayer(layer);
    this.customLayerIds = this.customLayerIds.filter(id => !ids.includes(id));
  }

  setCustomLayerColor(layer: ProjectMapLayer, color: string) {
    this.mapLayersRenderer.setLayerColor(layer, color);
  }

  setCustomLayerOpacity(layer: ProjectMapLayer, opacity: number) {
    this.mapLayersRenderer.setLayerOpacity(layer, opacity);
  }

  updateRedLineBoundary(map: mapboxgl.Map, boundary: Feature<Geometry> | null) {
    const source = map.getSource(this.REDLINE_SOURCE_NAME) as mapboxgl.GeoJSONSource;
    if (typeof source !== 'undefined') {
      source.setData(boundary ?? emptyFeatureCollection());
    }
  }

  setOverlaps(overlaps: FeatureCollection) {
    const source = this.map!.getSource('overlaps') as mapboxgl.GeoJSONSource;
    if (typeof source !== 'undefined') {
      source.setData(overlaps);
    }
  }

  setGaps(gaps: FeatureCollection) {
    const source = this.map!.getSource('gaps') as mapboxgl.GeoJSONSource;
    if (typeof source !== 'undefined') {
      source.setData(gaps);
    }
  }

  toggleDataLayer(layer: UKHabDataLayer) {
    this.dataLayerService.toggle(this.map!, layer);
  }

  toggleAllDataLayers(on: boolean) {
    this.dataLayerService.toggleAll(this.map!, on);
  }

  openSettings() {
    this.settingsOpen.set(true);
  }

  closeSettings() {
    this.settingsOpen.set(false);
  }

  setFeatureOpacity(pct: number, persist: boolean = this.persist) {
    this.featureOpacity.set(pct);
    this.map!.setPaintProperty('polygons', 'fill-opacity', fillOpacity(pct));
    if (persist) {
      localStorage.setItem(MapsService.STORAGE_OPACITY_KEY, pct.toString());
    }
  }

  fitBounds(bounds: mapboxgl.LngLatBoundsLike, options?: mapboxgl.FitBoundsOptions) {
    this.map!.fitBounds(bounds, options);
  }

  private resizeObservable(elem: HTMLElement) {
    return new Observable(subscriber => {
      var ro = new ResizeObserver(entries => {
        subscriber.next(entries);
      });

      // Observe one or multiple elements
      ro.observe(elem);
      return function unsubscribe() {
        ro.unobserve(elem);
      }
    });
  }

  getGlobalMaskLayer = (bounds: MultiPolygon | Polygon) => {
    const globalLayer = polygon([[
      [-180, -90],
      [-180, 90],
      [180, 90],
      [180, -90],
      [-180, -90]
    ]]);
    const appLayer = featureHelper(bounds);
    return difference(globalLayer, appLayer);
  }

  getMapGISSettings(): MapGISSettings {
    try {
      const settingsStr = localStorage.getItem('map-gis-settings');
      if (typeof settingsStr === 'string') {
        const parsed = JSON.parse(settingsStr);
        return { ...defaultMapGISSettings, ...parsed }
      }
      return defaultMapGISSettings;
    } catch (_e) {
      return defaultMapGISSettings;
    }

  }

  saveMapGISSettings(settings: Partial<MapGISSettings>) {
    const current = this.getMapGISSettings();
    window.localStorage.setItem('map-gis-settings', JSON.stringify({
      ...current,
      ...settings
    }));
  }
}

interface MapSettingsHost {
  openSettings(): void;
}
export class MapSettingsControl implements mapboxgl.IControl {

  private container: HTMLDivElement | undefined;
  private button: HTMLButtonElement | undefined;

  constructor(private component: MapSettingsHost) { }

  onAdd(): HTMLElement {
    const container = this.container = document.createElement('div');
    container.classList.add('mapboxgl-ctrl', 'mapboxgl-ctrl-group', 'mapboxgl-ctrl-map-settings');

    const button = this.button = document.createElement('button');
    button.classList.add('mapboxgl-ctrl-settings');

    const icon = document.createElement('img');
    icon.src = '/assets/icons/layers.svg';
    icon.style.width = '100%';
    icon.style.height = '100%';
    icon.style.padding = '4px';

    button.appendChild(icon);

    button.addEventListener('click', this.handleClick);
    container.appendChild(button);
    return container;
  }

  handleClick = () => {
    this.component.openSettings();
  }

  onRemove(_map: mapboxgl.Map): void {
    this.button?.removeEventListener('click', this.handleClick);
    this.container?.remove();
    this.container = undefined;
  }
}



export class MapboxBoxSelectControl implements mapboxgl.IControl {
  private container: HTMLDivElement | undefined;
  private mapContainer: HTMLElement | undefined;
  private map: mapboxgl.Map | undefined;
  private start: mapboxgl.Point;
  private current: mapboxgl.Point;
  private box: HTMLDivElement | null;

  private enabled: boolean = false;

  onAdd(map: mapboxgl.Map): HTMLElement {
    this.map = map;
    this.container = document.createElement('div');
    this.mapContainer = map.getCanvasContainer();
    this.mapContainer.addEventListener('mousedown', this.handleCanvasMouseDown, true);
    return this.container;
  }

  handleCanvasMouseDown = (e: MouseEvent) => {
    if (!(e.shiftKey && e.button === 0) || !this.enabled) {
      return;
    }

    this.map!.dragPan.disable();
    this.map!.dragRotate.disable();

    document.addEventListener('mousemove', this.handleCanvasMouseMove);
    document.addEventListener('mouseup', this.handleCanvasMouseUp);
    document.addEventListener('keydown', this.handleCanvasKeyDown);

    this.start = this.getMousePos(e);
    this.map?.fire('boxselectstart');
  }

  handleCanvasMouseMove = (e: MouseEvent) => {
    this.current = this.getMousePos(e);

    if (!this.box) {
      this.box = document.createElement('div');
      this.box.classList.add('boxdraw');
      this.mapContainer!.appendChild(this.box);
    }

    const minX = Math.min(this.start.x, this.current.x);
    const maxX = Math.max(this.start.x, this.current.x);
    const minY = Math.min(this.start.y, this.current.y);
    const maxY = Math.max(this.start.y, this.current.y);

    const pos = `translate(${minX}px, ${minY}px)`;
    this.box.style.transform = pos;
    this.box.style.width = `${maxX - minX}px`;
    this.box.style.height = `${maxY - minY}px`;
  }

  handleCanvasMouseUp = (e: MouseEvent) => {
    this.finishBox([this.start, this.getMousePos(e)]);
  }

  handleCanvasKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      this.finishBox();
    }
  }

  finishBox(bbox?: [mapboxgl.PointLike, mapboxgl.PointLike]) {
    document.removeEventListener('mousemove', this.handleCanvasMouseMove);
    document.removeEventListener('mouseup', this.handleCanvasMouseUp);
    document.removeEventListener('keydown', this.handleCanvasKeyDown);

    if (this.box) {
      this.box.parentNode?.removeChild(this.box);
      this.box = null;
    }

    if (bbox) {
      this.map?.fire('boxselect', { bbox });
    }
    this.map!.dragPan.enable();
    this.map?.fire('boxselectend');
  }

  getMousePos(e: MouseEvent) {
    const rect = this.mapContainer!.getBoundingClientRect();
    return new mapboxgl.Point(e.clientX - rect.left - this.map!.getCanvas().clientLeft, e.clientY - rect.top - this.map!.getCanvas().clientTop);
  }


  onRemove(): void {
    this.container?.parentNode?.removeChild(this.container);
    this.container = undefined;
    this.map = undefined;
    this.mapContainer = undefined;
  }

  setEnabled(enabled: boolean) {
    this.enabled = enabled
  }
}


export class MapLayersRenderer {

  constructor(private map: mapboxgl.Map) { }

  createLayer(layer: ProjectMapLayer): MapLayer {
    switch (layer.type) {
      case 'image': {
        return new MapImageLayer(this.map, layer);
      }
      case 'geojson': {
        return new MapGeoJSONLayer(this.map, layer);
      }
    }
  }

  renderLayers(layers: ProjectMapLayer[]) {
    const map = this.map;
    const ids: string[] = [];
    for (const layer of layers) {
      ids.push(...this.addLayer(layer));
    }
    return ids;
  }

  addLayer(layer: ProjectMapLayer) {
    return this.createLayer(layer).add();
  }

  removeLayer(layer: ProjectMapLayer) {
    return this.createLayer(layer).remove();
  }

  hideLayer(layer: ProjectMapLayer) {
    this.createLayer(layer).hide();
  }

  showLayer(layer: ProjectMapLayer) {
    this.createLayer(layer).show();
  }

  toggleLayer(layer: ProjectMapLayer) {
    return this.createLayer(layer).toggle();
  }

  setLayerOpacity(layer: ProjectMapLayer, opacity: number) {
    this.createLayer(layer).setOpacity(opacity);
  }

  setLayerColor(layer: ProjectMapLayer, color: string) {
    this.createLayer(layer).setColor(color);
  }
}

abstract class MapLayer {
  constructor(protected map: mapboxgl.Map, protected layer: ProjectMapLayer) { }
  abstract add(): string[];
  abstract remove(): string[];
  abstract mapLayerIds(): string[];
  abstract setOpacity(opacity: number): void;
  abstract setColor(color: string): void;

  protected mapLayerId(suffix = '') {
    return `layer-${this.layer.id}${suffix ? `-${suffix}` : ''}`;
  }

  show() {
    for (const layerId of this.mapLayerIds()) {
      this.map.setLayoutProperty(layerId, 'visibility', 'visible');
    }
  }

  hide() {
    for (const layerId of this.mapLayerIds()) {
      this.map.setLayoutProperty(layerId, 'visibility', 'none');
    }
  }

  toggle() {
    const visibility = this.map.getLayoutProperty(this.mapLayerIds()[0], 'visibility');
    if (visibility === 'none') {
      this.show();
      return true;
    } else {
      this.hide();
      return false;
    }
  }

}
class MapImageLayer extends MapLayer {

  add() {
    const { url, bounds } = this.layer;
    const layerId = this.mapLayerId();

    const source = this.map.getSource(layerId);
    if (typeof source === 'undefined') {
      const [minX, minY, maxX, maxY] = bounds;
      const coordinates: [number, number][] = [

      ]
      this.map.addSource(layerId, {
        type: 'image',
        url: url,
        // Provide coordinates as top-left, top-right, bottom-right, bottom-left
        coordinates: [
          [minX, maxY],
          [maxX, maxY],
          [maxX, minY],
          [minX, minY]
        ]
      });
    }

    const mapLayer = this.map.getLayer(layerId);
    if (typeof mapLayer === 'undefined') {
      this.map.addLayer({
        id: layerId,
        type: 'raster',
        source: layerId,
        paint: {
          'raster-opacity': 1.0
        }
      });
    }
    return [layerId];
  }

  remove() {
    const id = this.mapLayerId();
    safelyRemoveLayer(this.map, id);
    safelyRemoveSource(this.map, id);
    return [id];
  }

  mapLayerIds() {
    return [this.mapLayerId()];
  }

  setOpacity(opacity: number) {
    this.map.setPaintProperty(this.mapLayerId(), 'raster-opacity', opacity);
  }

  setColor(color: string) {
  }
}

class MapGeoJSONLayer extends MapLayer {

  add() {
    const layerId = this.mapLayerId();
    const { url, style } = this.layer;

    safelyAddSource(this.map, layerId, {
      type: 'geojson',
      data: url,
      generateId: true
    });

    const fillId = this.mapLayerId('fill');
    safelyAddLayer(this.map, {
      id: fillId,
      type: 'fill',
      source: layerId,
      filter: ['==', '$type', 'Polygon'],
      paint: {
        'fill-opacity': style?.opacity ?? 1.0,
        'fill-color': style?.color ?? '#000'
      }
    });

    const lineId = this.mapLayerId('line');
    safelyAddLayer(this.map, {
      id: lineId,
      type: 'line',
      source: layerId,
      filter: ['==', '$type', 'LineString'],
      paint: {
        'line-opacity': style?.opacity ?? 1.0,
        'line-color': style?.color ?? '#000'
      }
    });

    const circleId = this.mapLayerId('circle');
    safelyAddLayer(this.map, {
      id: circleId,
      type: 'circle',
      source: layerId,
      filter: ['==', '$type', 'Point'],
      paint: {
        'circle-opacity': style?.opacity ?? 1.0,
        'circle-color': style?.color ?? '#000'
      }
    });

    return [fillId, lineId, circleId];
  }

  remove() {
    const layerIds = this.mapLayerIds();
    for (const layerId of layerIds) {
      safelyRemoveLayer(this.map, layerId);
    }
    safelyRemoveSource(this.map, this.mapLayerId());
    return layerIds;
  }

  override mapLayerIds() {
    return [this.mapLayerId('fill'), this.mapLayerId('line'), this.mapLayerId('circle')];
  }

  setOpacity(opacity: number) {
    this.map.setPaintProperty(this.mapLayerId('fill'), 'fill-opacity', opacity);
    this.map.setPaintProperty(this.mapLayerId('line'), 'line-opacity', opacity);
    this.map.setPaintProperty(this.mapLayerId('circle'), 'circle-opacity', opacity);
  }

  setColor(color: string) {
    this.map.setPaintProperty(this.mapLayerId('fill'), 'fill-color', color);
    this.map.setPaintProperty(this.mapLayerId('line'), 'line-color', color);
    this.map.setPaintProperty(this.mapLayerId('circle'), 'circle-color', color);
  }
}
