import { DialogModule } from '@angular/cdk/dialog';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, importProvidersFrom, isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router } from '@angular/router';
import { browserTracingIntegration, createErrorHandler, init, replayIntegration, TraceService } from '@sentry/angular';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import jwt_decode from "jwt-decode";
import { EMPTY } from 'rxjs';
import { routes } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { tokenInterceptor } from './app/core/interceptors/token.interceptor';
import { User } from './app/core/models/user.model';
import { JwtService } from './app/core/services/jwt.service';
import { OrganisationsService } from './app/core/services/organisations.service';
import { UserService } from './app/core/services/user.service';

if (!isDevMode) {
  init({
    dsn: "https://1eaa95f7a5f6319e20c2a7655374d031@o416754.ingest.sentry.io/4506438843826176",
    integrations: [
      browserTracingIntegration(),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}


export function initAuth(jwtService: JwtService, userService: UserService, organisationService: OrganisationsService) {
  return () => {
    const token = jwtService.getToken();
    if (token) {
      const result = jwt_decode<{
        exp: number;
        displayName: string;
        email: string;
        userId: number;
        username: string;
        imageUrl: string;
        shareProjectId: number;
      }>(token);

      const {
        userId: id,
        displayName,
        email,
        imageUrl,
        username,
        shareProjectId
      } = result;

      // Check for token expiry
      const currentTime = Date.now() / 1000;
      if (result.exp < currentTime) {
        console.log('Token expired');
        jwtService.destroyToken();
        return EMPTY;
      }

      const user: User = {
        id,
        displayName,
        username,
        email,
        imageUrl,
        shareProjectId,
        role: ''
      };
      userService.setAuth(token, user);
      return organisationService.query();
    }
    return EMPTY;
  }
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      })
    },
    {
      provide: TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initAuth,
      deps: [JwtService, UserService, OrganisationsService],
      multi: true
    },
    provideRouter(routes),
    provideHttpClient(
      withInterceptors([tokenInterceptor])
    ),
    provideAngularSvgIcon(),
    importProvidersFrom(DialogModule),
    provideAnimations()
  ]
});
