import { HttpClient } from "@angular/common/http";
import { Injectable, computed, effect, inject, signal } from "@angular/core";
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from "@angular/router";
import { AllGeoJSON, MultiPolygon, area, bbox, clone, length } from "@turf/turf";
import { Feature, FeatureCollection, Geometry, Polygon } from "geojson";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { Observable, Subject, forkJoin, fromEvent, map, of, takeUntil, tap } from "rxjs";
import slufigy from "slugify";
import { PreSurvey, PreSurveySchema } from "src/app/core/models/presurvey.model";
import { Project, ProjectDetails, ProjectDetailsSchema, ProjectMapLayer, ProjectMapLayerSchema, ProjectVerificationState } from "src/app/core/models/project.model";
import { SpeciesFeatureNote, SpeciesFeatureNoteSchema } from "src/app/core/models/speciesfeaturenote.model";
import { Species, SpeciesSample, Survey, SurveyLog, SurveySchema } from "src/app/core/models/survey.model";
import { ProjectUser, ProjectUserSchema, User } from "src/app/core/models/user.model";
import { JwtService } from "src/app/core/services/jwt.service";
import { emptyFeatureCollection } from "src/app/core/services/maps.service";
import { environment } from "src/environments/environment";

export type GeometryFilter = Record<Geometry['type'], boolean | null>;

const emptyGeometryFilter: GeometryFilter = {
  Point: false,
  MultiPoint: false,
  LineString: false,
  MultiLineString: false,
  Polygon: false,
  MultiPolygon: false,
  GeometryCollection: false
};

export type METRIC_VERSION = 'statutory' | '4.0';

export type SurveyFeatureProperties = {
  [P in keyof Omit<Survey, 'images' | 'type' | 'userId' | 'species' | 'samples' | 'state' | 'logs'>]: string | undefined | number;
} & {
  id: number | string;
  l2?: string;
}

const views = [
  'baseline',
  'pre-survey',
  'species-feature-notes'
] as const;

type VIEW = typeof views[number];

type OverlapsFeatureCollection = FeatureCollection<Polygon | MultiPolygon, { aId: number | string; bId: number | string; aName: string; bName: string; area: number }>;
type GapsFeatureCollection = FeatureCollection<Polygon | MultiPolygon, { area: number }>;

interface SurveyResponse {
  id: number;
  samples: SpeciesSample[];
  species: Species[];
  speciesResult: {
    spsTotal: number;
    spsPerQ: number;
    nativeWSps: number;
    qRecorded: number;
  }
}

// const emptyFeatureCollection = <T = any>(): FeatureCollection<Geometry, T> => ({
//   type: 'FeatureCollection',
//   features: []
// });

@Injectable()
export class ProjectService {

  router = inject(Router);
  httpClient = inject(HttpClient);
  jwtService = inject(JwtService);
  route = inject(ActivatedRoute);

  public readonly project = signal<ProjectDetails | null>(null);

  public readonly presurveys = signal<PreSurvey[]>([]);
  public readonly states = signal<ProjectVerificationState[]>([]);
  public readonly users = signal<ProjectUser[]>([]);
  public readonly baseline = signal<Survey[]>([]);
  public readonly speciesFeatureNotes = signal<SpeciesFeatureNote[]>([]);
  public readonly mapLayers = signal<ProjectMapLayer[]>([]);

  public readonly projectId = computed(() => this.project()?.id ?? null);
  public readonly redLineBoundary = computed<Feature<MultiPolygon> | null>(() => {
    const project = this.project();
    if (!project || !project.geometry) {
      return null;
    }
    return {
      type: 'Feature',
      geometry: project.geometry,
      properties: {}
    }
  });
  // public readonly speciesFeatureNotes = computed(() => this.project()?.speciesFeatureNotes ?? []);
  public readonly isV3 = computed(() => {
    return this.project()?.ukHabVersion === 'ukhabappv3';
  });
  public readonly slug = computed(() => {
    const project = this.project();
    return slufigy(project?.name ?? 'ukhab', {
      lower: true,
      trim: true,
      strict: true
    });
  });
  public readonly isAdmin = computed(() => this.project()?.role === 'admin');

  public readonly view = signal<VIEW>('baseline');
  public readonly sidebarOpen = signal<boolean>(false);
  public readonly mapMaximized = signal<boolean>(false);

  public readonly isMapEditing = signal<boolean>(false);

  public readonly selectedIds = signal<number[]>([]);
  public readonly selectedSource = signal<'map' | 'table' | null>(null);

  public readonly hoveredId = signal<[number | null, number | null]>([null, null]);
  public readonly currentHoveredId = computed(() => this.hoveredId()[0]);

  public readonly activeBaselineId = signal<number | null>(null);
  public readonly activeBaseline = computed(() => {
    const id = this.activeBaselineId();
    return this.baseline().find(s => s.id === id);
  });
  public readonly baselineHabitatCodes = computed(() => this.baseline().map(s => s.ukHabPCode));

  public readonly hiddenIds = signal<number[]>([]);
  public readonly hiddenFilter = computed(() => {
    const hiddenIds = this.hiddenIds();
    let ids = [...hiddenIds];
    let filter: any[] = ['all'];
    if (ids.length > 0) {
      ids.forEach(id => filter.push(['!=', ['id'], ['literal', id]]));
      return filter;
    } else {
      return null;
    }
  });

  private readonly geometryEdits = signal<Record<number, Geometry>>({});
  public readonly hasEdits = computed(() => {
    return Object.keys(this.geometryEdits()).length > 0;
  });

  public readonly idFilter = signal<number | null>(null);
  public readonly userFilter = signal<number | null>(null);
  public readonly stateFilter = signal<number | null>(null);
  public readonly habitatFilter = signal<string[]>([]);
  public readonly geometryFilter = signal<GeometryFilter>({ ...emptyGeometryFilter });
  public readonly dataLayerFeature = signal<MapboxGeoJSONFeature | null>(null);

  public readonly baselineFiltered = computed(() => {
    const id = this.idFilter();
    const userId = this.userFilter();
    const stateId = this.stateFilter();
    const habitatFilter = this.habitatFilter();
    const geometryFilter = this.geometryFilter();
    const baseline = this.baseline();

    if (id) {
      return baseline.filter(s => s.id === id);
    }
    const geometry = Object.keys(geometryFilter).filter(key => geometryFilter[key as Geometry['type']] === true);

    if (!habitatFilter.length && !geometry.length && !userId && stateId === null) return baseline;
    const asd = baseline.filter(s => {
      const code = s.ukHabPCode;
      const type = s.geometry?.type as Geometry['type'];
      return (
        (!habitatFilter.length || habitatFilter.some(h => code?.startsWith(h))) &&
        (!geometry.length || geometry.includes(type)) &&
        (!userId || s.userId === userId) &&
        (stateId === null || s.state === stateId)
      );
    });
    return asd;
  });

  public readonly baselineSelected = computed(() => {
    const selected = this.selectedIds();
    return this.baseline().filter(s => selected.includes(s.id));
  });

  public readonly features = computed<FeatureCollection<Geometry, any>>(() => {
    const project = this.project();
    const surveys = this.baseline();
    const presurveys = this.presurveys();

    if (!project) {
      return this.emptyFeatureCollection();
    }

    const features: Feature<Geometry, any>[] = [];

    for (const survey of surveys.filter(g => !!g.geometry)) {
      features.push({
        type: 'Feature',
        geometry: survey.geometry,
        id: survey.id,
        properties: {
          __type: 'baseline',
          id: survey.id ?? '',
          area: survey.area ?? 0,
          createdAt: survey.createdAt ?? '',
          updatedAt: survey.updatedAt ?? '',
          length: survey.length ?? '',
          distinctiv: survey.distinctiv ?? '',
          ukHabPCode: survey.ukHabPCode ?? '',
          ukHabPName: survey.ukHabPName ?? '',
          ukHabCCode: survey.ukHabCCode ?? '',
          conNotes: survey.conNotes ?? '',
          conCrit: survey.conCrit ?? '',
          conScore: survey.conScore ?? '',
          conResult: survey.conResult ?? '',
          metBUnits: survey.metBUnits ?? '',
          metBrdHab: survey.metBrdHab ?? '',
          metHUnits: survey.metHUnits ?? '',
          metHab: survey.metHab ?? '',
          metHabType: survey.metHabType ?? '',
          metMsg: survey.metMsg ?? '',
          spsTotal: survey.spsTotal ?? '',
          spsPerQ: survey.spsPerQ ?? '',
          nativeWSps: survey.nativeWSps ?? '',
          qRecorded: survey.qRecorded ?? '',
          ukHabS1Cod: survey.ukHabS1Cod ?? '',
          ukHabS1Nam: survey.ukHabS1Nam ?? '',
          ukHabS2Cod: survey.ukHabS2Cod ?? '',
          ukHabS2Nam: survey.ukHabS2Nam ?? '',
          ukHabS3Cod: survey.ukHabS3Cod ?? '',
          ukHabS3Nam: survey.ukHabS3Nam ?? '',
          ukHabS4Cod: survey.ukHabS4Cod ?? '',
          ukHabS4Nam: survey.ukHabS4Nam ?? '',
          ukHabS5Cod: survey.ukHabS5Cod ?? '',
          ukHabS5Nam: survey.ukHabS5Nam ?? '',
          ukHabS6Cod: survey.ukHabS6Cod ?? '',
          ukHabS6Nam: survey.ukHabS6Nam ?? '',
          l2: survey.ukHabPCode?.slice(0, 1),
          l3: survey.ukHabPCode?.slice(0, 2),
          l4: survey.ukHabPCode?.slice(0, 3),
          l5: survey.ukHabPCode?.slice(0, 4),
          featName: survey.featName ?? '',
          stratSig: survey.stratSig ?? '',
          userId: survey.userId ?? '',
          state: survey.state ?? '',
        }
      });
    }

    for (const presurvey of presurveys.filter(g => !!g.geometry)) {
      features.push({
        type: 'Feature',
        id: presurvey.id,
        geometry: presurvey.geometry,
        properties: {
          ...presurvey,
          __type: 'pre-survey',
          ukHabPCode: presurvey.UKHab_L2 ?? ''
        }
      })
    }

    for (const sfn of (this.speciesFeatureNotes().filter(g => !!g.geometry))) {
      features.push({
        type: 'Feature',
        id: sfn.id,
        geometry: sfn.geometry,
        properties: {
          ...sfn,
          __type: 'species-feature-notes',
        }
      })
    }

    return {
      type: 'FeatureCollection',
      features
    };
  });

  public readonly featuresWithEdits = computed(() => {
    const featuresWithEdits: FeatureCollection<Geometry, any> = clone(this.features() as AllGeoJSON);
    for (const [id, geometry] of Object.entries(this.geometryEdits())) {
      const idx = featuresWithEdits.features.findIndex(f => f.id === +id);
      if (idx === -1) {
        console.warn('Could not find edited feature??');
        continue;
      }
      featuresWithEdits.features[idx].geometry = geometry;
    }
    return featuresWithEdits;
  });

  public readonly baselineWithEdits = computed(() => {
    const features: FeatureCollection<Geometry, any> = clone(this.featuresWithEdits() as AllGeoJSON);
    return {
      ...features,
      features: features.features.filter(f => f.properties.__type === 'baseline')
    }
  });

  public readonly featuresBoundingBox = computed(() => {
    const features = this.features();
    if (!features.features.length) {
      return null;
    }
    return bbox(features) as mapboxgl.LngLatBoundsLike;
  });

  public readonly viewFeaturesBoundingBox = computed(() => {
    const features = this.features().features.filter(f => f.properties.__type === this.view())
    if (!features.length) {
      return null;
    }
    return bbox({ type: 'FeatureCollection', features }) as mapboxgl.LngLatBoundsLike;
  });

  public readonly geometryTypes = computed(() => {
    const types: Geometry['type'][] = [];
    for (const record of this.baseline() ?? []) {
      if (record.geometry?.type && !types.includes(record.geometry.type)) {
        types.push(record.geometry.type);
      }
    }
    return types;
  });

  public readonly hoveredFeature = computed(() => {
    const id = this.currentHoveredId();
    if (!id) {
      return null;
    }
    return this.dataLayerFeature()
      ? this.dataLayerFeature()
      : this.features().features.find(f => f.id === id);
  });

  public readonly redLineBoundaryBox = computed(() => {
    const boundary = this.redLineBoundary();
    if (!boundary) {
      return null;
    }
    return bbox(boundary) as mapboxgl.LngLatBoundsLike;
  });

  public readonly mapFilter = computed<mapboxgl.Expression>(() => {
    const idFilter = this.idFilter();
    const userId = this.userFilter();
    const stateId = this.stateFilter();
    const habitatFilter = this.habitatFilter();
    const geometryFilter = this.geometryFilter();
    const hiddenFilter = this.hiddenFilter();
    const view = this.view();

    const expression: mapboxgl.Expression = ['all', ['==', ['get', '__type'], ['literal', view]]];

    if (hiddenFilter) {
      expression.push(hiddenFilter);
    }

    if (idFilter) {
      return ['==', ['id'], ['literal', idFilter]] as mapboxgl.Expression;
    }

    const geometry = Object.keys(geometryFilter).filter(key => geometryFilter[key as Geometry['type']] === true);

    if (!habitatFilter.length && !geometry.length && !userId && stateId === null) {
      return expression;
    }

    if (geometry.length > 0) {
      expression.push(['in', ['geometry-type'], ['literal', geometry]]);
    }

    if (habitatFilter.length > 0) {
      const minLevel = 2;
      const maxLevel = 5;
      for (let i = minLevel; i <= maxLevel; i++) {
        const habitatLevel = habitatFilter.filter(h => h.length === (i - 1));
        habitatLevel.length > 0 && expression.push(['in', ['get', `l${i}`], ['literal', habitatLevel]]);
      }
    }

    if (userId) {
      expression.push(['in', ['get', 'userId'], ['literal', [userId]]])
    }

    if (stateId !== null) {
      expression.push(['in', ['get', 'state'], ['literal', [stateId]]])
    }
    return expression;
  });

  private readonly overlaps = signal<OverlapsFeatureCollection>({
    type: 'FeatureCollection',
    features: []
  });

  private readonly gaps = signal<GapsFeatureCollection>({
    type: 'FeatureCollection',
    features: []
  });

  // Minimum threshold for overlap area, in square meters
  public readonly overlapsMin = signal<number>(this.getInitialOverlapMin());

  public readonly filteredOverlaps = computed(() => {
    const min = this.overlapsMin();
    return {
      ...this.overlaps(),
      features: this.overlaps().features.filter(f => f.properties.area > min)
    };
  });

  public readonly filteredGaps = computed(() => {
    const min = this.overlapsMin();
    return {
      ...this.gaps(),
      features: this.gaps().features.filter(f => f.properties.area > min)
    };
  });

  public getInitialOverlapMin() {
    const v = localStorage.getItem('overlapsMin') ?? '1';
    return parseInt(v);
  }

  public readonly showOverlaps = signal<boolean>(false);
  public readonly showGaps = signal<boolean>(false);

  public sigs: { id: string, name: string }[] = [
    { id: 'low_significance', name: 'Low (1.0)' },
    { id: 'medium_significance', name: 'Medium (1.1)' },
    { id: 'high_significance', name: 'High (1.15)' },
  ] as const;

  private sidebarUrls: string[] = [
    'sidebar:activity',
    'sidebar:filter',
    'sidebar:geometry',
    'sidebar:stats',
  ];

  private overlapsWorker: Worker;
  private gapsWorker: Worker;

  destroy$ = new Subject<void>();


  constructor() {

    this.overlapsWorker = new Worker(new URL('./overlaps.worker', import.meta.url));
    this.gapsWorker = new Worker(new URL('./gaps.worker', import.meta.url));

    fromEvent<any>(this.overlapsWorker, 'message').pipe(
      takeUntil(this.destroy$)
    ).subscribe(({ data }) => {
      this.overlaps.set(JSON.parse(data));
    });

    fromEvent<any>(this.gapsWorker, 'message').pipe(
      takeUntil(this.destroy$)
    ).subscribe(({ data }) => {
      this.gaps.set(JSON.parse(data));
    });

    effect(() => {
      const features = this.baselineWithEdits();
      const showOverlaps = this.showOverlaps();
      this.overlapsWorker.postMessage(JSON.stringify(showOverlaps ? features : emptyFeatureCollection()));
    });

    effect(() => {
      const features = this.baselineWithEdits();
      const showGaps = this.showGaps();
      const rlb = this.redLineBoundary();

      this.gapsWorker.postMessage(JSON.stringify({
        features: showGaps ? features : emptyFeatureCollection(),
        rlb
      }));
    })

    effect(() => {
      localStorage.setItem('overlapsMin', this.overlapsMin().toString());
    });

    this.handleRouting(this.router.url);
    this.router.events.subscribe(t => {
      if (t instanceof NavigationEnd) {
        this.handleRouting(t.url);
      }
    });


  }

  ngOnDestroy() {
    this.overlapsWorker.terminate();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateView(view: VIEW) {
    this.clearSelected();
    this.geometryEdits.set({});
    this.isMapEditing.set(false);
    this.view.set(view);
  }

  handleRouting(url: string) {
    const g = this.router.parseUrl(url);
    const s = g.root.children[PRIMARY_OUTLET];
    if (s) {
      const view = s.segments[s.segments.length - 1].path;
      this.clearHidden();
      this.clearSelected();
      if (views.includes(view as VIEW)) {
        this.updateView(view as VIEW);
      }
    }
    this.sidebarOpen.set(this.sidebarUrls.some(u => url.includes(u)));
  }

  toggleMapMaximized() {
    this.mapMaximized.update(maximized => !maximized);
  }

  public setHoveredId(id: number | null) {
    const current = this.currentHoveredId();
    if (current === id) {
      return;
    }
    this.hoveredId.set([id, current]);
  }

  public select(id: number | number[], source: 'map' | 'table', isShiftPressed: boolean = false) {
    this.selectedSource.set(source);

    if (!isShiftPressed || Array.isArray(id)) {
      return this.selectedIds.set(Array.isArray(id) ? id : [id]);
    }
    const ids = this.selectedIds();
    const selectedIds = ids.includes(id) ? ids.filter(id => id !== id) : [...ids, id];
    this.selectedIds.set([...selectedIds]);
  }

  public toggleSidebar() {
    this.sidebarOpen.update(open => !open);
  }

  public getSelectedIds() {
    return this.selectedIds();
  }

  public clearSelected() {
    this.selectedIds.set([]);
    this.selectedSource.set(null);
  }

  public selectAll() {
    const view = this.view();
    let ids: number[] = [];
    switch (view) {
      case 'baseline':
        ids = this.baseline().map(s => s.id);
        break;
      case 'pre-survey':
        ids = this.presurveys()!.map(s => s.id);
        break;
      case 'species-feature-notes':
        ids = this.speciesFeatureNotes().map(s => s.id);
        break;
    }
    /** Ensure any hidden records are made visible */
    this.clearHidden();
    this.selectedIds.set(ids);
  }

  public showRecord(id: number) {
    const ids = this.hiddenIds();
    this.hiddenIds.set(ids.filter(i => i !== id));
  }

  public hideRecord(id: number) {
    const ids = this.hiddenIds();
    this.hiddenIds.set([...ids, id]);
  }

  public clearHidden() {
    this.hiddenIds.set([]);
  }

  public toggleRecordVisibility(id: number) {
    const ids = this.hiddenIds();
    ids.includes(id) ? this.showRecord(id) : this.hideRecord(id);
  }

  public startMapEditing() {
    this.isMapEditing.set(true);
  }

  public setIdFilter(id: number | null) {
    this.clearSelected();
    this.idFilter.set(id);
  }

  public setGeometryFilter(filter: Partial<GeometryFilter>) {
    this.geometryFilter.update(f => ({ ...f, ...filter }));
  }

  public setHabitatFilter(filter: string[]) {
    const sortedFilter = filter
      .sort((a: string, b: string) => a.length - b.length)
      .filter((item: string, index: number) =>
        !filter.slice(0, index)
          .some((prefix: string) => item.startsWith(prefix))
      );
    this.habitatFilter.set([...sortedFilter]);
  }

  public setUserFilter(userId: number | null) {
    this.userFilter.set(userId);
  }

  public setStateFilter(stateId: number | null) {
    this.stateFilter.set(stateId);
  }

  private createGeometry(geometry: Geometry) {
    const project = this.project()!;
    // TODO - handle presurveys
    const input = [{ geometry }];

    const url = `${environment.ukhabApiUrl}/projects/${project.id}/presurveys`;
    return this.httpClient.post<{ id: number; area: number; length: number }[]>(url, input).subscribe((res) => {
      const [presurvey] = res;
      this.presurveys.update(p => [
        ...p,
        {
          id: presurvey.id,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          geometry,
          UKHab_L2: '',
          area: (geometry.type === 'Polygon' || geometry.type === 'MultiPolygon') ? area({ type: 'Feature', geometry, properties: {} }) / 10000 : undefined,
          length: (geometry.type === 'LineString' || geometry.type === 'MultiLineString') ? length({ type: 'Feature', properties: {}, geometry }, { units: 'kilometers' }) : undefined
        }
      ]);
    });
  }

  public updateGeometry(updates: Record<number, Geometry>) {
    const project = this.project()!;
    const view = this.view();
    const key: keyof Project = view === 'baseline' ? 'surveys' : 'presurveys';
    const urlKey = view === 'baseline' ? 'baselines' : 'presurveys';
    const dataSignal = view === 'baseline' ? this.baseline : this.presurveys;

    const data = dataSignal().slice(0);
    const obs = [];

    for (const [id, geometry] of Object.entries(updates)) {
      let existingIdx = data.findIndex(s => s.id === +id);
      if (existingIdx === -1) {
        continue;
      }
      let existing = data[existingIdx];
      existing.geometry = geometry;
      if (existing.hasOwnProperty('type')) {
        (existing as any).type = geometry.type;
      }

      if (geometry.type === 'Polygon' || geometry.type === 'MultiPolygon') {
        existing.length = 0;
        existing.area = area({
          type: 'Feature',
          geometry,
          properties: {}
        }) / 10000;
      } else if (geometry.type === 'LineString' || geometry.type === 'MultiLineString') {
        existing.area = 0;
        existing.length = length(geometry as any, { units: 'kilometers' });
      }

      if (view === 'baseline') {
        existing = {
          ...existing,
          ...this.calculateUnits(existing as Survey)
        }
      }
      data[existingIdx] = existing;
      const url = `${environment.ukhabApiUrl}/projects/${project.id}/${urlKey}/${id}/geometry`;
      obs.push(this.httpClient.put(url, { geometry }));
    }

    dataSignal.set(data as any[]);
    forkJoin(obs).subscribe();
  }

  public updateSurveyGeometry(id: number, geometry: Geometry) {
    const changes = this.geometryEdits();
    changes[id] = geometry;
    this.geometryEdits.set({
      ...changes
    });
  }

  public stopMapEditing(save = false) {
    const changes = this.geometryEdits();

    if (!save) {
      this.geometryEdits.set({});
      this.isMapEditing.set(false);
      return;
    }

    // If we are creating something
    const features = this.features();
    const baseline = this.baseline();

    const updates: Record<number, Geometry> = {};
    for (const [id, geometry] of Object.entries(changes)) {
      const existing = features.features.findIndex(f => f.id === +id);
      if (existing !== -1) {
        updates[+id] = geometry;
      } else {
        // See if it's a baseline with a new geometry
        if (typeof baseline.find(s => s.id === +id) !== 'undefined') {
          updates[+id] = geometry;
        } else {
          this.createGeometry(geometry);
        }
      }
    }
    if (Object.keys(updates).length > 0) {
      this.updateGeometry(updates);
    }
    this.geometryEdits.set({});
    this.isMapEditing.set(false);
  }

  deleteBaselines(ids: number[]) {
    const url = `${environment.ukhabApiUrl}/projects/${this.project()!.id}/baselines`;
    const project = this.project()!;
    return this.httpClient.delete(url, {
      body: ids
    }).subscribe(() => {
      // this.baseline.set(project.surveys.filter(s => !ids.includes(s.id)));
      // project.surveys = project.surveys.filter(s => !ids.includes(s.id));
      this.baseline.update(b => b.filter(s => !ids.includes(s.id)));
      this.clearSelected();
      // this.project.set({
      //   ...project
      // });
    });
  }

  deleteSFNs(ids: number[]) {
    const url = `${environment.ukhabApiUrl}/projects/${this.project()!.id}/sfns`;
    return this.httpClient.delete(url, {
      body: ids
    }).subscribe(() => {
      this.speciesFeatureNotes.update(s => s.filter(s => !ids.includes(s.id)));
      this.clearSelected();
    });
  }

  deletePresurveys(ids: number[]) {
    const url = `${environment.ukhabApiUrl}/projects/${this.project()!.id}/presurveys`;
    const project = this.project()!;
    return this.httpClient.delete(url, {
      body: ids
    }).subscribe(() => {
      // project.presurveys = project.presurveys.filter(s => !ids.includes(s.id));
      this.presurveys.update(p => p.filter(s => !ids.includes(s.id)));
      this.clearSelected();
      this.project.set({
        ...project
      });
    });
  }

  deleteSelected() {
    const selected = this.selectedIds();
    switch (this.view()) {
      case 'baseline':
        return this.deleteBaselines(selected);
      case 'pre-survey':
        return this.deletePresurveys(selected);
      case 'species-feature-notes':
        return this.deleteSFNs(selected);
    }
  }

  updateL2(id: number, l2: string) {
    const project = this.project()!
    const url = `${environment.ukhabApiUrl}/projects/${project.id}/presurveys/${id}`;
    return this.httpClient.put(url, { l2 }).subscribe(() => {
      this.presurveys.update(ps => {
        const idx = ps.findIndex(s => s.id === id);
        if (idx === -1) {
          return ps;
        }
        return [
          ...ps.slice(0, idx),
          { ...ps[idx], UKHab_L2: l2 },
          ...ps.slice(idx + 1)
        ];
      });
      // const idx = project.presurveys.findIndex(s => s.id === id);
      // if (idx === -1) {
      //   return;
      // }
      // const presurveys = [
      //   ...project.presurveys.slice(0, idx),
      //   { ...project.presurveys[idx], UKHab_L2: l2 },
      //   ...project.presurveys.slice(idx + 1)
      // ];
      // this.project.set({ ...project, presurveys });
    });
  }

  updateBaselineFeatureName(id: number, featName: string) {
    return this.updateBaseline([{ id, featName }]);
  }

  updateStrategicSignificance(updates: { id: number, stratSig: string }[]) {
    return this.updateBaseline(updates);
  }

  calculateUnits(survey: Survey) {
    const { conScore, area, length, distinctiv, stratSig } = survey;
    const sigMap = {
      'high_significance': 1.15,
      'medium_significance': 1.1,
      'low_significance': 1.00,
    };

    const sigModifier = sigMap[stratSig as keyof typeof sigMap] ?? 1.0;
    let metHUnits = 0;
    let metBUnits = 0;


    if (conScore && distinctiv) {
      if (area) {
        metBUnits = conScore * area * distinctiv * sigModifier;
      }
      if (length) {
        metHUnits = conScore * length * distinctiv * sigModifier;
      }
    }

    return {
      metBUnits,
      metHUnits
    }

  }

  patchBaseline(updates: Partial<Survey>[]) {
    const surveys = this.baseline().slice(0);
    for (const update of updates) {
      const idx = surveys.findIndex(s => s.id === update.id);
      const updatedSurvey = {
        ...surveys[idx],
        ...update
      };
      surveys[idx] = {
        ...updatedSurvey,
        ...this.calculateUnits(updatedSurvey)
      };
      // Recalculate metric units
    }
    this.baseline.set(surveys);
  }

  updateBaseline(updates: Partial<Survey>[]) {
    const url = `${environment.ukhabApiUrl}/projects/${this.project()!.id}/baselines`;
    return this.httpClient.put(url, updates).subscribe(() => {
      this.patchBaseline(updates);
    });
  }

  createMapLayer(file: File, type: 'image' | 'geojson', name: string): Observable<ProjectMapLayer> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    formData.append('name', name);
    const url = `${environment.ukhabApiUrl}/projects/${this.projectId()}/layers`;
    return this.httpClient.post<ProjectMapLayer>(url, formData).pipe(
      tap(layer => this.mapLayers.update(layers => [...layers, layer]))
    );
  }

  updateMapLayer(layer: Partial<ProjectMapLayer>) {
    const url = `${environment.ukhabApiUrl}/projects/${this.projectId()}/layers/${layer.id}`;
    return this.httpClient.put<ProjectMapLayer>(url, layer).pipe(
      tap(layer => this.mapLayers.update(layers => {
        const idx = layers.findIndex(l => l.id === layer.id);
        if (idx === -1) {
          return layers;
        }
        return [
          ...layers.slice(0, idx),
          { ...layers[idx], ...layer },
          ...layers.slice(idx + 1)
        ];
      })
      ));
  }

  removeMapLayer(id: number) {
    const url = `${environment.ukhabApiUrl}/projects/${this.projectId()}/layers/${id}`;
    return this.httpClient.delete(url).pipe(
      tap(() => this.mapLayers.update(layers => layers.filter(l => l.id !== id)))
    );
  }

  getRecordLogs(recordId: number): Observable<SurveyLog[]> {
    const url = `${environment.ukhabApiUrl}/projects/${this.project()!.id}/baselines/${recordId}/logs`;
    return this.httpClient.get<SurveyLog[]>(url);
  }

  getProjectLogs(): Observable<SurveyLog[]> {
    const project = this.project()!;
    return this.httpClient.get<SurveyLog[]>(`${environment.ukhabApiUrl}/projects/${project.id}/logs`);
  }

  updateBaselineStatus(id: number, state: number) {
    const url = `${environment.ukhabApiUrl}/projects/${this.project()!.id}/baselines/${id}/state`;
    return this.httpClient.put(url, { state }).subscribe(() => {
      this.patchBaseline([{ id, state }]);
    });
  }



  private emptyFeatureCollection(): FeatureCollection<Geometry, any> {
    return {
      type: 'FeatureCollection',
      features: []
    };
  }

  public getState(stateId: number) {
    return this.states().find(s => s.stateId === stateId);
  }

  getProjectDetails(id: number): Observable<ProjectDetails> {
    return this.httpClient.get<Project>(`${environment.ukhabApiUrl}/projects/${id}`).pipe(
      map((project) => ProjectDetailsSchema.parse(project))
    );
  }

  getBaselines(id: number): Observable<Survey[]> {
    return this.httpClient.get<Survey[]>(`${environment.ukhabApiUrl}/projects/${id}/baselines`).pipe(
      map((surveys) => SurveySchema.array().parse(surveys))
    );
  }

  getUsers(id: number): Observable<ProjectUser[]> {
    return this.httpClient.get<User[]>(`${environment.ukhabApiUrl}/projects/${id}/members`).pipe(
      map((users) => ProjectUserSchema.array().parse(users))
    );
  }

  getPreSurveys(id: number): Observable<PreSurvey[]> {
    return this.httpClient.get<PreSurvey[]>(`${environment.ukhabApiUrl}/projects/${id}/presurveys`).pipe(
      map((presurveys) => PreSurveySchema.array().parse(presurveys))
    );
  }

  getSpeciesFeatureNotes(id: number): Observable<SpeciesFeatureNote[]> {
    return this.httpClient.get<SpeciesFeatureNote[]>(`${environment.ukhabApiUrl}/projects/${id}/sfns`).pipe(
      map((sfns) => SpeciesFeatureNoteSchema.array().parse(sfns))
    );
  }

  getRedLineBoundary(id: number): Observable<Feature<Polygon | MultiPolygon> | null> {
    return this.httpClient.get<Feature<Polygon | MultiPolygon> | null>(`${environment.ukhabApiUrl}/projects/${id}/geometry`);
  }

  // Reloads the presuveys and returns any new ones
  getMapLayers(id: number): Observable<ProjectMapLayer[]> {
    return this.httpClient.get<any[]>(`${environment.ukhabApiUrl}/projects/${id}/layers`).pipe(
      map(layers => ProjectMapLayerSchema.array().parse(layers))
    )
  }

  reloadPreSurveys() {
    const project = this.project();
    if (!project) {
      return of([]);
    }

    return this.getPreSurveys(project.id).pipe(
      map((presurveys) => {
        const current = this.presurveys();
        this.presurveys.set(presurveys)
        return presurveys.filter(p => !current.some(c => c.id === p.id));
      })
    );
  }

  get(id: number): Observable<ProjectDetails> {
    const project = this.project();
    if (project && project.id === id) {
      return of(project);
    }

    const projectGet = this.getProjectDetails(id);
    const surveysGet = this.getBaselines(id);
    const usersGet = this.getUsers(id);
    const preSurveysGet = this.getPreSurveys(id);
    const speciesFeatureNotesGet = this.getSpeciesFeatureNotes(id);
    const layersGet = this.getMapLayers(id);

    return forkJoin([projectGet, surveysGet, usersGet, preSurveysGet, speciesFeatureNotesGet, layersGet]).pipe(
      map(([p, s, u, ps, sfn, layers]) => {
        this.states.set(p.states);
        this.presurveys.set(ps);
        this.users.set(u);
        this.baseline.set(s);
        this.speciesFeatureNotes.set(sfn);
        this.mapLayers.set(layers);
        this.project.set(p);
        return p;
      })
    );
  }

  update(data: Omit<Partial<ProjectDetails>, 'id'>) {
    const url = `${environment.ukhabApiUrl}/projects/${this.projectId()}`;
    return this.httpClient.put(url, data).pipe(
      tap(() => {
        this.project.update(project => ({
          ...project!,
          ...data
        }))
      })
    )
  }

  navigateToSurvey(surveyId: number) {
    let route = this.route.root;
    let p: string = '';

    while (route.firstChild) {
      route = route.firstChild;
      const path = route.snapshot.url.map(segment => segment.path).join('/');

      if (path.startsWith('projects')) {
        p = 'projects';
        break;
      } else if (path.startsWith('share')) {
        p = 'share';
        break;
      }
    }
    this.router.navigateByUrl(`/${p}/${this.project()!.id}/baseline/${surveyId}`);
  }
}
