<nav
  class="w-full h-16 bg-white grid justify-between items-center px-5 leading-10 text-black border-b border-[#B9C5D0]">
  <div class="flex items-center flex-grow flex-shrink-0 max-md:hidden">
    <a [routerLink]="backLink()">
      <svg-icon class="block h-10 text-primary" src="/assets/coreo.svg" />
    </a>
  </div>

  <!-- Organisation Menu -->
  @if(canSwitchOrg()){
  <div class="flex-grow flex items-center justify-center">
    <img src="/assets/logo.svg" class="h-10 pr-2 pl-5" alt="UKHab Portal Logo" />
    <h1 class="text-center font-semibold text-lg max-lg:hidden">The UKHab &amp; BNG Platform</h1>
  </div>
  } @else{
  <app-project-header class="flex-grow" />
  }

  <div class="flex-1 flex justify-end">
    @if(loggedIn()){
    <button [cdkMenuTriggerFor]="orgs" [class.org-disabled]="!canSwitchOrg()"
      class="button-white text-sm font-semibold h-8 px-3 mx-2 flex items-center shrink-0">
      <span class="text-gray-400 mr-2">Organisation:</span>
      <span class="whitespace-pre">{{organisation()?.name}}</span>
      @if(canSwitchOrg()){
      <svg-icon src="/assets/icons/arrow.svg" class="text-xs text-primary w-4 h-4 ml-1" />
      }
    </button>
    }
    @if(loggedIn()){
    <button [cdkMenuTriggerFor]="menu" class="h-full flex items-center shrink-0">
      <img [src]="image()" class="h-8 w-8 rounded-full" />
    </button>
    }
    @if(isGuest()){
    <button (click)="exitGuestMode()" class="button-white px-3 text-sm font-semibold">Exit Guest Mode</button>
    }
  </div>


  <ng-template #menu>
    <ul class="dropdown" cdkMenu>
      <li cdkMenuItem (click)="logout()">Logout</li>
    </ul>
  </ng-template>

  <ng-template #orgs>
    <ul class="dropdown" cdkMenu>
      @for(org of organisations(); track org.id){
      <li (click)="switchOrganisation(org)" cdkMenuItem>{{org.name}}</li>
      }
    </ul>
  </ng-template>

</nav>