import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { HttpClient } from "@angular/common/http";
import { Component, inject, signal } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { map, switchMap, tap } from "rxjs";
import { environment } from "src/environments/environment";

@Component({
  template: `
  <form (ngSubmit)="submit($event)" [formGroup]="form">
    <div class="flex flex-col min-w-80">
    <label for="share-password">Password</label>
    <input id="share-password" type="password" required
                formControlName="password"
                placeholder="Enter the project password"
                class="block w-full rounded-md border-0 px-2 py-1.5 h-11 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6" />
    @if(error()){
      <p class="text-danger py-2">Invalid password</p>
    }
  </div>
  <button type="submit" class="mt-2 px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50" [disabled]="!form.valid">
      Submit
    </button>
</form>`,
  standalone: true,
  imports: [
    ReactiveFormsModule
  ]
})
export class SharePasswordComponent {

  private httpClient = inject(HttpClient);
  private dialogRef = inject(DialogRef<string>);

  error = signal(false);
  form = new FormGroup({
    password: new FormControl('', { validators: [Validators.required] })
  });

  data: {
    projectId: number;
  } = inject(DIALOG_DATA);

  submit(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    const password = this.form.get('password')?.value;
    this.error.set(false);

    return this.httpClient.post<{ token: string }>(`${environment.ukhabApiUrl}/share/${this.data.projectId}`, { password }).subscribe(response => {
      if (response.token) {
        return this.dialogRef.close(response.token);
      }
      this.form.patchValue({ password: '' });
      this.error.set(true);
    });
  }
}
