<app-layout-header>
</app-layout-header>
<main class="overflow-y-auto bg-background">
  <router-outlet></router-outlet>
</main>
<!-- Placeholder for small screens and smartphones. -->
<div class="absolute top-0 left-0 w-full h-full bg-[#F3F7FC] z-50 hidden max-sm:block">
  <div class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-[80vw] rounded-xl bg-white shadow-lg overflow-hidden">
    <div class="flex items-center justify-center p-8 bg-primary">
      <svg-icon class="block h-24 text-white" src="/assets/coreo.svg"/>
    </div>
    <div class="flex flex-col items-center justify-center p-8">
      <p class="text-lg text-[#444444] font-semibold text-center mb-7">
        To give you the best experience the Coreo UKHab & 
        BNG Platform is optimised for larger screens
      </p>
      <span class="text-sm text-[#878F97] text-center">Try dragging your screen to a larger size</span>
    </div>
  </div>
</div>
